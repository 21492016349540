import CryptoJS from 'crypto-js';

export class DesencriptarBackend {
    chaveGerada = '';

    gerarChaveEmComum(chaves) {
        const composta = chaves.reduce((acc, chave) => `${acc}-${chave}`, '');
        this.chaveGerada = composta.slice(0, 32);
    }

    desencriptar(dadosEncriptados) {
        const chave = CryptoJS.enc.Utf8.parse(this.chaveGerada);

        const [ivHex, textoEncriptado] = dadosEncriptados.split(':');

        const iv = CryptoJS.enc.Hex.parse(ivHex);
        const ciphertext = CryptoJS.enc.Hex.parse(textoEncriptado);

        const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, chave, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}

<template>
    <div class="flex flex-nowrap w-full mt-6 mb-4">
        <Button
            :loading="loading"
            aria-label="Voltar"
            class="border-200"
            icon="pi pi-chevron-left"
            style="color: #3f51b5; background-color: #f8fafc; width: 40px; height: 40px"
            @click="emit('prevPage')"
        />
        <div class="w-full mr-4 text-center font-bold poppins-medium" style="font-size: 26px">Local da consulta</div>
    </div>
    <span class="text-center mt-2" style="font-size: 18px"> Selecione a clínica de sua preferência </span>
    <div class="mt-4 w-full">
        <label class="text-2xl text-700" for="cidade"> Cidade </label>
        <AppOnePageDropdown
            id="cidade"
            v-model="agendamento.cidade"
            :loading="loading"
            :service="$serviceCidades"
            :renderOption="(slotProps) => slotProps.option?.cidade + ' - ' + slotProps.option?.estado"
            :renderValue="(slotProps) => slotProps.value?.cidade + ' - ' + slotProps.value?.estado"
            class="w-full mt-1 border-2 border-round-xl"
            inputClass="p-4 text-xl font-medium"
            optionLabel="cidade"
            placeholder="Selecione a cidade"
            @change="(agendamento.prestador = null), formatarEndereco()"
        />
    </div>
    <div class="mt-4 w-full">
        <label class="w-full" for="prestador">
            Prestador<span class="p-error">*</span>
            <a
                :style="{ visibility: !agendamento.prestador ? 'hidden' : 'unset' }"
                href="javascript:void(0)"
                style="float: right"
                @click="mostrarInformacoesPrestador($event, agendamento.prestador)"
            >
                Ver informações da cliníca
            </a>
        </label>
        <AppOnePageDropdown
            id="prestador"
            ref="prestadores"
            :modelValue="agendamento.prestador"
            :autoLoad="false"
            :disabled="!agendamento.cidade"
            :filtrosExtras="filtrosExtras"
            :loading="loading"
            :renderOption="formatarPrestadores"
            :resultWithItems="false"
            :service="$servicePrestadores"
            class="w-full mt-1 border-2 border-round-xl"
            inputClass="p-4 text-xl font-medium"
            optionLabel="name"
            placeholder="Selecione o prestador"
            @update:modelValue="verificarSePrestadorTemHorarioDisponivel($event)"
        />
    </div>
    <div class="mt-4 w-full">
        <label class="text-2xl text-700" for="endereco"> Endereço </label>
        <InputText id="endereco" v-model="endereco" disabled class="w-full p-4 mt-1 border-2 border-round-xl text-xl font-medium" type="text" />
    </div>
    <div ref="map" id="map" class="m-4 h-full w-full" style="min-height: 230px" />
    <div class="flex align-items-center w-full">
        <div style="height: 12px; width: 12px; border-radius: 50%; background-color: #e0158f" />
        <label for="termos" class="ml-2 text-md"> Prestadores indicados </label>
    </div>
    <div class="flex align-items-center w-full mb-4">
        <div style="height: 12px; width: 12px; border-radius: 50%; background-color: #f1749e" />
        <label for="termos" class="ml-2 text-md"> Outros prestadores </label>
    </div>
    <Button
        :disabled="!camposEstaoPreenchidos"
        :loading="loading"
        class="border-none text-xl w-full mt-auto mb-5 py-3 poppins-medium"
        label="Escolher Prestador"
        rounded
        style="background-color: #3f51b5"
        @click="atualizarAgendamento()"
    />
    <OverlayInformacoesClinica v-if="modalInfoHorariosPrestador" v-model="modalInfoHorariosPrestador" :prestadorId="agendamento.prestador.id" />
</template>

<script setup>
import { ref, defineEmits, computed, onActivated, onMounted, watch, reactive } from 'vue';
import { getAutoagendamentoToken } from '@/common/storage';
import { useToast } from 'primevue/usetoast';
import BaseService from '@/services/BaseService';
import { getClientBase } from '@/services/http';
import { logoutAutoagendamento } from '../../../services/auth';
import { Loader } from '@googlemaps/js-api-loader';

import dayjs from 'dayjs';
import OverlayInformacoesClinica from '../components/OverlayInformacoesClinica.vue';
import { DesencriptarBackend } from '@/utils/DesencriptarBackend';

const emit = defineEmits(['prevPage', 'nextPage']);

const autoagendamentoToken = getAutoagendamentoToken();
const auxHeaders = { Authorization: `Bearer ${autoagendamentoToken}` };
const $serviceCidades = new BaseService('/autoagendamento/cidades', auxHeaders);
const gMaps = reactive({
    builder: new Loader({
        apiKey: 'toBeLoaded',
        version: 'weekly'
    })
});

const toast = useToast();
const loading = ref(false);
const endereco = ref('');
const filtrosExtras = {
    agruparPrestadores: true,
    apenasQueAtendamClinico: true
};
const agendamento = ref({
    cliente: null,
    cidade: null,
    prestador: null
});
const prestadores = ref(null);
const map = ref(null);
const marker = ref([]);

const camposEstaoPreenchidos = computed(() => {
    return !!agendamento.value.cidade && !!agendamento.value.prestador;
});
const $servicePrestadores = computed(() => {
    if (!agendamento.value.cidade) return;
    const cidadeId = agendamento.value.cidade?.id;
    const url = `/autoagendamento/suppliers/${cidadeId}`;
    return new BaseService(url, auxHeaders);
});

watch(
    () => prestadores.value?.optionItems,
    () => {
        carregarLocalizacaoMapa();
    }
);

onActivated(() => {
    buscarCidadePrestador();
});

onMounted(async () => {
    const data = await obterMapaLoader();

    gMaps.builder.apiKey = data.apiKey;
    gMaps.builder.load().then(async () => {
        const { Map: GMap } = await window.google.maps.importLibrary('maps');
        const centerOfBrazil = { lat: -14.235, lng: -51.9253 };
        map.value = new GMap(document.getElementById('map'), {
            center: centerOfBrazil,
            zoom: 3,
            styles: [
                {
                    featureType: 'all',
                    elementType: 'all',
                    stylers: [{ saturation: -100 }, { lightness: 70 }]
                }
            ]
        });
    });
});
async function obterMapaLoader() {
    const gmapsLoaderKey = new BaseService('autoagendamento/local/prestadores-mapa-loader', auxHeaders);
    const { data } = await gmapsLoaderKey.findAll({});

    const comunicacao = new DesencriptarBackend();
    await obterChaveComum(comunicacao);
    const gMapKey = comunicacao.desencriptar(data.apiKeyEncripted);
    return { ...data, apiKey: gMapKey };
}

async function obterChaveComum(comunicacao) {
    const { id, name, dataNascimento } = await obterColaborador();
    comunicacao.gerarChaveEmComum([dataNascimento.toISOString(), name, `${id}`]);
}

async function obterColaborador() {
    const { data } = await getClientBase(auxHeaders).get('/autoagendamento/colaborador');
    const colaborador = {
        id: data.id,
        name: data.name,
        dataNascimento: dayjs(data.dataNascimento).toDate()
    };
    return colaborador;
}

async function atualizarAgendamento() {
    loading.value = true;
    try {
        await getClientBase(auxHeaders).patch('/autoagendamento/confirmar-prestador', {
            cidade: agendamento.value.cidade,
            idPrestador: agendamento.value.prestador.id
        });
        emit('nextPage');
    } catch (error) {
        mostraErroEDesloga(error, 'Erro ao confirmar prestador');
    }
    loading.value = false;
}

function mostraErroEDesloga(error, erroVazioMsg) {
    const { message, statusCode } = error?.response?.data || { message: erroVazioMsg, statusCode: 500 };
    if (statusCode === 401) logoutAutoagendamento();
    toast.add({
        severity: 'error',
        summary: message,
        life: 3000
    });
}
async function buscarCidadePrestador() {
    loading.value = true;
    try {
        const { data } = await getClientBase(auxHeaders).get('/autoagendamento/cidade-prestador');
        agendamento.value = data;
        formatarEndereco();
    } catch (error) {
        mostraErroEDesloga(error, 'Erro ao buscar cidade e prestador');
    }
    loading.value = false;
}

async function carregarLocalizacaoMapa() {
    if (prestadores.value?.optionItems[0]?.name) {
        let prestadoresSlice;
        try {
            const { data } = await new BaseService('/autoagendamento/local/prestadores-mapa-marcadores', auxHeaders).findAll({
                filtrosExtras: { prestadores: prestadores.value.optionItems.slice(0, 15) }
            });
            prestadoresSlice = data;
        } catch (err) {
            toast.add({
                severity: 'error',
                summary: err?.response?.data?.message,
                life: 3000
            });
        }
        if (!prestadoresSlice) return;

        const prestadoresComLatitude = prestadoresSlice.filter((e) => e?.latitude);
        const montarObjetoMapa = prestadoresComLatitude.map((prestador) => ({
            id: prestador.id,
            endereco: `${prestador.city.cidade} - ${prestador.street}, ${prestador.number}, ${prestador.neighborhood}, ${prestador.zipCode}`,
            center: {
                lat: prestador.latitude,
                lng: prestador.longitude
            },
            priority: prestador.prioridade,
            description: prestador.street,
            titulo: prestador.name,
            icon:
                'data:image/svg+xml;charset=UTF-8,' +
                encodeURIComponent(`<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="930.000000pt" height="1280.000000pt" viewBox="0 0 930.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
            fill="${prestador.prioridade === 'PREFERENCIAL' ? '#E0158F' : '#F1749E'}" stroke="none">
            <path d="M4335 12789 c-1496 -104 -2843 -915 -3635 -2190 -232 -373 -414 -787
            -529 -1204 -305 -1107 -197 -2278 305 -3295 191 -387 372 -660 676 -1020 34
            -41 753 -976 1596 -2077 918 -1199 1555 -2022 1588 -2052 186 -170 442 -170
            628 0 33 30 670 853 1588 2052 843 1101 1562 2036 1596 2077 304 360 485 633
            676 1020 566 1147 629 2502 174 3695 -353 923 -967 1689 -1798 2242 -825 549
            -1864 821 -2865 752z m559 -2254 c224 -29 398 -81 601 -180 553 -268 931 -756
            1062 -1374 25 -116 27 -145 28 -366 0 -267 -10 -345 -70 -555 -161 -561 -586
            -1032 -1130 -1253 -201 -82 -365 -120 -592 -139 -294 -25 -593 23 -878 139
            -544 221 -969 692 -1130 1253 -60 210 -70 288 -70 555 1 221 3 250 28 366 112
            527 406 965 842 1252 177 116 437 227 637 271 209 46 467 58 672 31z"/>
            </g>
            </svg>`)
        }));

        await gMaps.builder.load().then(async () => {
            const { Map: GMap } = await window.google.maps.importLibrary('maps');

            const centerOfBrazil = { lat: -14.235, lng: -51.9253 };

            map.value = await new GMap(document.getElementById('map'), {
                center: centerOfBrazil,
                zoom: 3,
                mapTypeControl: false,
                styles: [
                    {
                        featureType: 'all',
                        elementType: 'all',
                        stylers: [{ saturation: -100 }, { lightness: 70 }]
                    }
                ]
            });

            montarObjetoMapa.forEach((marcador) => {
                criaMarcadorMapa(marcador);
            });
            fitBounds();
        });
    }
}

function criaMarcadorMapa(marcador) {
    const createMarcador = new window.google.maps.Marker({
        position: marcador.center,
        map: map.value,
        id: marcador.id,
        title: marcador.titulo,
        icon: {
            url: marcador.icon,
            scaledSize: marcador.priority === 'PREFERENCIAL' ? new window.google.maps.Size(40, 40) : new window.google.maps.Size(20, 20)
        }
    });

    const contentString = `<div>
                    <h4>${marcador.titulo}</h4>
                    <p>${marcador.endereco}</p>
                    </div>`;

    const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
        ariaLabel: 'Uluru'
    });

    createMarcador.addListener('click', () => {
        const marcadorId = createMarcador.id;

        const prestadorFilter = prestadores.value.optionItems.filter((e) => e.id === Number(marcadorId));

        agendamento.value.prestador = prestadorFilter[0];

        formatarEndereco();

        infowindow.open({
            anchor: createMarcador,
            map
        });
    });

    marker.value = [...marker.value, createMarcador];
}

function fitBounds() {
    const bounds = new window.google.maps.LatLngBounds();
    marker.value.forEach((marker) => {
        bounds.extend(marker.getPosition());
    });

    map.value.fitBounds(bounds);
    const maxZoom = 2;
    map.value.setZoom(maxZoom);
}
async function focalizarPrestadorMapa() {
    const marcadorPrestadorSelecionado = marker.value.filter((marcador) => Number(marcador.id) === Number(agendamento.value?.prestador?.id));
    if (marcadorPrestadorSelecionado) {
        if (!map.value || typeof map?.value?.fitBounds !== 'function') {
            await carregarLocalizacaoMapa();
        }
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(marcadorPrestadorSelecionado[0]?.getPosition());
        map?.value?.fitBounds(bounds);
        const maxZoom = 15;
        if (map.value.getZoom() > maxZoom) {
            map.value.setZoom(maxZoom);
        }
    }
}

function formatarEndereco() {
    const cidade = agendamento.value.cidade?.cidade;
    if (cidade) endereco.value = cidade;

    if (agendamento.value.prestador) {
        const { zipCode, neighborhood, street, number } = agendamento.value.prestador;
        endereco.value = `${cidade} - ${street}, ${neighborhood}, ${number}, ${zipCode}`;
    }
}
function formatarPrestadores({ option }) {
    const { code, name, city } = option;

    return `${code} - ${name} | ${city?.cidade} - ${city?.estado}`;
}
async function verificarSePrestadorTemHorarioDisponivel(prestador) {
    const prestadorUsaSoc = prestador?.systemsAndTechnology?.name === 'SOC';
    const usaReservaHorario = agendamento.value.cliente?.usaReservaHorario === true;

    if (prestadorUsaSoc && usaReservaHorario) {
        loading.value = true;
        try {
            const { data } = await getClientBase(auxHeaders).get('/autoagendamento/data-sugestao');
            const dataFinal = dayjs(data.dataSugestaoFinal).toDate();
            const dataInicial = dayjs(data.dataSugestaoInicial).toDate();

            const { data: faixasDeHorario } = await getClientBase(auxHeaders).get(`autoagendamento/faixas-disponiveis`, {
                params: { dataInicial, dataFinal, idPrestador: prestador.id }
            });

            if (!faixasDeHorario?.length) {
                loading.value = false;
                toast.add({
                    severity: 'warn',
                    summary: 'Este prestador não possui horário diponível no range de datas solicitado',
                    detail: 'Por favor, selecione outro prestador ou entre em contato com o responsável para solicitar um novo range de datas',
                    life: 8000
                });
                return;
            }
            focalizarPrestadorMapa();
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: error?.response?.data.message,
                life: 3000
            });
        }
        loading.value = false;
    }

    agendamento.value.prestador = prestador || null;
    focalizarPrestadorMapa();
    formatarEndereco();
}

const prestador = ref(null);
const modalInfoHorariosPrestador = ref(false);

function mostrarInformacoesPrestador(event, prestadorInfo) {
    prestador.value = prestadorInfo;
    modalInfoHorariosPrestador.value = true;
}
</script>
